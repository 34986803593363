import React from 'react';
import FluidImage from '~/components/common/FluidImage/FluidImage';
import Layout from '~/components/layout/layout/layout';
import SEO from '~/components/common/SEO/SEO';
import TrailerSection from '~/components/pages/trailers/TrailerSection';
import HeroContainer from '~/components/layout/hero-container/hero-container';

const TrailersPage = () => (
  <Layout>
    <SEO title="Trailers" />
    <HeroContainer title="Trailers">
      <FluidImage imageName="synopsis.jpeg" />
    </HeroContainer>
    <TrailerSection />
  </Layout>
);

export default TrailersPage;

import React from 'react';
import Section from '../../layout/section/section';
import style from './TrailerSection.module.scss';

const TrailerSection = () => {
  return (
    <Section
      className={style.TrailerSection}
      innerClassName={style.content}
      size="sm"
    >
      <div className={style.youtubeVideo}>
        <h5>#3 WAHID: KILL ALL DAESH TRAILER 1</h5>
        <iframe
          width="100%"
          height="400"
          src="https://www.youtube.com/embed/Sjsqsj_Mvr4"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
      <div className={style.youtubeVideo}>
        <h5>#2 WAHID: DAWN OF EVIL TRAILER 1</h5>
        <iframe
          width="100%"
          height="400"
          src="https://www.youtube.com/embed/4wSnDkkfA1M"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
      <div className={style.youtubeVideo}>
        <h5>#1 WAHID: WEAPON OF MASS DESTRUCTION TRAILER 2</h5>
        <iframe
          width="100%"
          height="400"
          src="https://www.youtube.com/embed/4S5MnnpGo5M"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
      <div className={style.youtubeVideo}>
        <h5>#1 WAHID: WEAPON OF MASS DESTRUCTION TRAILER 1</h5>
        <iframe
          width="100%"
          height="400"
          src="https://www.youtube.com/embed/7X1wXGZXMxQ"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </Section>
  );
};

export default TrailerSection;
